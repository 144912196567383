/* -----------------------------------------------Main Section------------------------------------- */

#bmainSection{
    position: absolute;
    height: 380px;
    left: 0px;
    top: 235px;
    width: 100%;
    overflow-x: hidden;
    
}

#bsec1 h1{
   
     /* width: 332px; */
    /*height: 89px; */
    /* left: 804px; */
    position: absolute;
    margin-left: -20px;
    top:350px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 57px;
    line-height: 89px;
    /* identical to box height */
    
    /* background-color: aqua; */
    color: #FFFFFF;
   
    
}


#bhomeTocovid h6{
    position: absolute;
    /* width: 250px; */
    /*height: 22px; */
    /* left: 891.5px; */
    top: 474px;
    margin-left: 20px;
    font-family: Nunito;
    font-style: normal;
    text-align: center;
    font-size: 17px;
    line-height: 20px;
    /* identical to box height */

    
    color: #FFFFFF;
    overflow: hidden;
}

#bhomeTocovid h6{
    overflow: hidden;
}

#bhomeTocovid span{
    opacity: 0.6;
}

/* --------------------------------------------------Blog para---------------------------------------------- */

#blogPara{
    margin-top: 500px;
}

#typing{
    width: 600px;
    height: 350px;
    padding-bottom: 50px;
    
}

#underTyping{
    font-weight: bold;
    
}

#underTyping h3{
    font-weight: 700;
}

#smallImg{
    width: 105px;
    height: 76px;
}

#popText h5{
    font-size: 17px;
    margin-left: 50px;
}

#popText p{
    font-size: 13px;
    margin-left: 50px;
    opacity: 0.6;
}

.sidebar-item-tags{
    margin-left: -30px;
    
}

.sidebar-item-tags ul li {
    
	display: inline-block;
    
}

.sidebar-item-tags ul li {
	margin-bottom: 5px;
   
   
}

.sidebar-item-tags ul li a {
	font-weight: 600;
font-size: 14px;
	background-color: #f9f9f9;
    padding: 6px 18px;
	display: inline-block;
	
	margin-right: 5px;
   text-decoration: none;
   color: black;
	
}

/* -----------------------------------------------------Blog 1 Footer-------------------------------------------------- */

.blog1Footer{
    margin-top: 100px;
}
