/* body{
    overflow-x: hidden;
} */


#nameErrors{
   
    color: rgb(255, 0, 0);
    width: 216px;
    font-weight: 400;
   
   
}

#emailErrors{
  
    color: rgb(255, 0, 0);
    width: 216px;
    font-weight: 400;
   
    
}

#subjectErrors{
    
    color: rgb(255, 0, 0);
    width: 450px;
    font-weight: 400;
   
   
}

/* #commentErrors{
    position: absolute;
    color: rgb(235, 31, 31);
    width: 450px;
    font-weight: 400;
    z-index: 1;
    margin-top: 370px;
} */

#successMessage{
    color: rgb(10, 252, 10);
    font-weight: 400;
    font-size: 20px; 
}




.forSwal {
    height: 250px;
  
}



/* ------------------------------Welcome-------------------------------------- */
#section1{
    overflow-y: hidden;
    padding-left: 60px;
    
}

#backgroundImg{
    position: absolute;
    width: 100%;
    height: 652px;
    left: 0px;
    top: 235px;
    overflow-y: hidden;
}

#scientist{
    /* position: absolute; */
    /* width: 513px; */
    height: 470px;
    margin-left: -60px;
    margin-top: 90px;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
    border-radius: 10px;
    overflow-y: hidden;
}

#section1 h2{
    position: absolute;
    width: 653px;
    height: 178px;
    /* padding-left: 20px; */
    /* left: 240px; */
    top: 410px;
    font-weight: 700;
    font-size: 55px;
    line-height: 75px;
    /* White */
    color: #FFFFFF;
    overflow-y: hidden;
    padding-right: 20px;
}

#section1 h2 span{
    font-weight: 400;
}

#section1 p{
    position: absolute;
    width: 470px;
    height: 54px;
    /* padding-left: 30px; */
    /* left: 240px; */
    top: 580px;
    font-weight: normal;
    font-size: 17px;
    line-height: 27px;
    /* White */
    color: #FFFFFF;
    padding-right: 20px;
    overflow-y: hidden;
}

.learnMore{
    padding: 15px 18px;
    position: absolute;
    width: 139px;
    height: 49px;
    /* margin-left: 25px; */
    /* left: 240.18px; */
    top: 660px;
    /* White */
    background: #FFFFFF;
    border-radius: 5px;
    border:none;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    text-transform: uppercase;
    padding-right: 20px;
    /* Gray 1 */
    color: #333333;
    overflow-y: hidden;
   
}

/* .learnMore:hover{
    background-color:#038AF6;
    color: #FFFFFF;
    border-color: #ffffff;
} */

/* -------------------------------------------Services----------------------------------------- */

.fullServices h1{
    /* position: absolute; */
    /* width: 814px; */
    height: 75px;
    /* left: 235px; */
    margin-top: 200px;
    font-weight: normal;
    font-size: 45px;
    line-height: 70px;
    /* identical to box height */
    text-transform: uppercase;
    /* Gray 1 */
    color: #333333;
    /* background-color: aqua; */
}

.fullServices p{
    /* position: absolute; */
    /* width: 745.71px; */
    height: 54px;
    /* left: 235px; */
    top: 1095px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
    /* Gray 2 */
    color: #4F4F4F;
}

.fullServices h1 span{
    color: #038AF6;
    font-weight: 800;
}

.learnBT{
    padding: 14px 17px;
    /* position: absolute; */
    /* width: 130px; */
    height: 47px;
    margin-left: 70px;
    /* left: 1175px; */
    margin-top: 215px;
    /* 1 */
    background: #038AF6;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    /* identical to box height */
    text-transform: uppercase;
    /* White */
    color: #FFFFFF;
    border:none;
}

#firstCards{
    /* position: absolute; */
    width: 510px;
    height: 250px;
    margin-top: 80px;
    /*left: 250px; */
    
    background: #FFFFFF;
    border-radius: 10px;
    padding: 0px;
    border: none;
}   

#firstCards:hover{
    border: none;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    /* transition: width 10s ease, background-color 10s ease; */
}
#firstCards a{
    text-decoration: none;
    color: #038AF6;
    font-weight: 700;
}

#secondCards{
    /* position: absolute; */
    width: 510px;
    height: 250px;
    margin-top: 30px;
    /* margin-left: 10px; */
    /* left: 250px; */
    background: #FFFFFF;
    border-radius: 10px;
    padding: 0px;
    margin-bottom: 50px;
    border: none;
}

#secondCards:hover{
    border: none;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    
}

#secondCards a{
    text-decoration: none;
    color: #038AF6;
    font-weight: 700;
}

.Card1{
    width: 60px;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10%;
}

.Card2{
    width: 60px;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10%;
}

.Card3{
    width: 60px;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10%;
}

.Card4{
    width: 60px;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10%;
}


/* ------------------------------------------IT Services--------------------------------------- */
#it_services{
    /* position: absolute; */
    /* width: 200%; */
    height: 658px;
    left: 0px;
    top: 1800px;
    background-image: url("it_servicebg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    overflow-x: hidden;
}

#it_services h2{
  
    height: 61px;
    margin-top: 119.75px;
    font-size: 40px;
    font-weight: 400;
    line-height: 58px;
    text-transform: uppercase;
    /* White */
    color: #FFFFFF;
}

#it_services h2 span{
    font-weight: 800;
}

#wheels{
  
    margin-left: -20px;
    margin-top: 100px;
    text-align: center;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    /* text-align: center; */
    text-transform: uppercase;

    /* White */

    color: #FFFFFF;
}

#wheels p{
    margin-top: 50px;
}

/* #wheel1{
    position: static;
    width: 250px;
    height: 230px;
    /* left: 280.61px; 
    top: 407.58px;

}

#wheel2{
    position: static;
    width: 250px;
    height: 230px;
    /* left: 645.51px; 
    top: 307.58px;
}

#wheel3{
    position: static;
    width: 250px;
    height: 230px;
    /* left: 1000.37px; 
    top: 307.58px;
} */
/*  */

#text1{
    position: absolute;
    /* width: 180px;
    height:40px;
    left: 300.61px; */
    top: 550px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    /* text-align: center; */
    text-transform: uppercase;

    /* White */

    color: #FFFFFF;
    /* margin-left: -520px; */
}

#text2{
    position: absolute;
    width: 110px;
    height: 40px;
    /* left: 700.51px; */
    top: 550px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    /* White */

    color: #FFFFFF;
    /* margin-left: -30px; */
}

#text3{
    position: absolute;
    width: 205px;
    height: 40px;
    left: 1017.37px;
    top: 550px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    /* White */

    color: #FFFFFF;
    /* margin-left: -10px; */
}



/* -------------------------------------Happy Clients--------------------------------------- */
#hcimg1{
    /* position: absolute; */
    /* width: 413px; */
    height: 503px;
    /* left: 750px; */
    margin-top: 150px;
    /* margin-bottom: 100px; */
    overflow: hidden;
    
   
    /* background-color: black; */

}

#happyCard{
    position: absolute;
    width: 600px;
    height: 355px;
    left: 270px;
    top: 2700px;
    z-index: 1;
    /* White */

    border: none;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
}

#hc1{
    position: absolute;
    width: 165px;
    height: 27px;
    left: 30px; 
    /* top: 3027px; */
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-transform: uppercase;

    /* 1 */

    color: #038AF6;
}

#hc2{
    position: absolute;
    width: 561px;
    height: 75px;
    left: 30px;
    top: 61px;

    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 75px;
    /* identical to box height */

    text-transform: uppercase;

    /* Gray 1 */

    color: #333333;
}   

#hc2 span{
    color:#038AF6;
    font-weight: 800;
}

#hc3{
    position: absolute;
    width: 73.35px;
    height: 73.35px;
    left: 30px;
    top: 151px;
}

#hc4{
    position: absolute;
    width: 111px;
    height: 27px;
    left: 130.17px;
    top: 171px;

    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-transform: uppercase;

    /* Gray 1 */

    color: #333333;
}

#hc5{
    position: absolute;
    width: 507.21px;
    height: 88px;
    left: 30px;
    top: 250px;

    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;

    /* Gray 3 */

    color: #828282;

}



/* ---------------------------------------------Clients--------------------------------------- */
#clients{
    /* position: absolute; */
    height: 409px;
    left: 0px;
    margin-top: 150px;
    /* Gray 7 */
    background: #F9F9F9;
}

#clients h2{
    color: #333333;
    margin-top: 100px;
    font-size: 50px;
    height: 70px;
}

#clients h2 span{
    color: #038AF6;
    font-weight: 800;
}

#secondRow{
 
  margin-left: auto;
  margin-right: auto;
   
    /* height: 100px; */
    /* left: 220px; */
    margin-top: 50px;
    
   

}


/* ---------------------------------------Contact------------------------------------------ */

#contactSection{
    /* position: absolute; */
    height: 648px;
    left: 0px;
    margin-top: 0px;
    /* padding-bottom: 4000px; */
    /* 1 */
    background-image: url("contactbg.jpg");
    background-position: center;
}

/* #forContact{
    /* margin-left: 80px; 
} */

#forContact h3{
    /* position: absolute; */
    width: 432px;
    height: 75px;
    /* left: 239px; */
    margin-top: 100px;
    
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 45px;
    line-height: 75px;
    /* identical to box height */
    
    text-align: right;
    text-transform: uppercase;
    
    /* White */
    
    color: #FFFFFF;
}

#forContact h3 span{
    font-weight: 800;
}

#divider{
    position: absolute;
    width: 500px;
    height: 0px;
    left: 33%;
    margin-top: 330px;
    margin-right: 200px;
    /* Gray 5 */
    border: 2px solid #E0E0E0;
    transform: rotate(90deg);
}

/* #c2{
    position: absolute;
    width: 200.65px;
    height: 31px;
    left: 470.35px;
    top: 213.75px;
    
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    text-align: right;
    
    /* White 
    
    color: #FFFFFF;
}

#c3{
    position: absolute;
    width: 186px;
    height: 22px;
    left: 485px;
    top: 251.75px;

    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height 

    text-align: right;

    /* White 

    color: #FFFFFF;

}

#c4{
    position: absolute;
    width: 200.65px;
    height: 31px;
    left: 470.35px;
    top: 310.75px;

    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    text-align: right;

    /* White 

    color: #FFFFFF;

}
#c5{
    position: absolute;
    width: 191px;
    height: 22px;
    left: 480px;
    top: 350.75px;
    
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height 
    
    text-align: right;
    
    /* White 
    
    color: #FFFFFF;
} */
#c6{
    /* position: absolute; */
    width: 440px;
    
    /* left: 213px; */
    margin-top: 61.75px;

    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    text-align: right;

    /* White */

    color: #FFFFFF;
}

#c7{
    /* position: absolute; */
    width: 437px;
    
    /* left: 245px; */
    margin-top: 60.75px;
    
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    text-align: right;
    
    /* White */
    
    color: #FFFFFF;
}

#g1{
    /* position: absolute; */
    width: 498px;
    height: 75px;
    /* left: 810px; */
    margin-top: 100px;

    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 45px;
    line-height: 75px;
    /* identical to box height */

    text-transform: uppercase;

    /* White */

    color: #FFFFFF;
}

#g1 span{
    font-weight: 800;
}


#inputName4{
    height: 50px;
    /* width: 216px;  */
    /* margin-left: 46px; */
    margin-top: 55px;
    border-radius: 5px;

}


#inputEmail4{
    height: 50px;
    /* width: 216px; */
   
    margin-top: 55px;
    border-radius: 5px;

}


#inputSubject{
    height: 50px;
    /* width: 450px; */
    /* margin-left: 46px; */
    /* margin-top: 250px; */
    border-radius: 5px;

}

#exampleFormControlTextarea1{
    height: 130px;
    /* width: 450px; */
    /* margin-left: 46px; */
    /* margin-top: 270px; */
    border-radius: 5px;

}


#g6{
    padding: 15px 10px;
    /* position: absolute; */
    width: 123px;
    height: 49px;
    /* left: 819px; */
    top: 520px;
    font-size: 14px;
    line-height: 19px;
    /* White*/
    font-weight: bold;
    background: #FFFFFF;
    border-radius: 5px;
    overflow-y: hidden;
    border:none;
} 


/* ---------------------------------------------Home Footer----------------------------------------- */
.homeFooter{
    margin-top: 0px;
}

