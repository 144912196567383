/* ------------------------------------Section 1--------------------------------------- */
#latestsection1 img{
    position: absolute;
    height: 380px;
    left: 0px;
    top: 235px;
    width: 100%;
    overflow-x: hidden;
    
}

#latestsec1 h1{
   
     /* width: 332px; */
    /*height: 89px; */
    /* left: 804px; */
    position: absolute;
    /* margin-left: 100px; */
    top:350px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    line-height: 89px;
    /* identical to box height */
    
    /* background-color: aqua; */
    color: #FFFFFF;
   
    
}


#latesthomeTocovid h6{
    position: absolute;
    /* width: 250px; */
    /*height: 22px; */
    /* left: 891.5px; */
    top: 474px;
    margin-left: -20px;
    font-family: Nunito;
    font-style: normal;
   
    font-size: 17px;
    line-height: 20px;
    /* identical to box height */

    
    color: #FFFFFF;
    overflow: hidden;
}

#latesthomeTocovid h6{
    overflow: hidden;
}

#latesthomeTocovid span{
    opacity: 0.6;
}


/* ---------------------------------------------------Cards Section--------------------------------------- */
#latestCards{
    margin-top: 500px;
    margin-left: 40px;
    
}

#lc1{
    width: 570px;
    height:490px;
    border-radius: 10px;
    transition: box-shadow .5s;
    /* border-color: #464a4d; */
  
    
}

#toBlog1{
    text-decoration: none;
}

#lc1:hover{
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border-radius: 10px;
    border: none;
    clip-path: inset(-5px 0px 0px -15px);
   
}

#cfDates{
    margin-top: -5px;
}

#latestDate{
    color: #ffffff;
    /* padding-top: -800px; */
    font-size: 15px;
}

#lc1 h5{
/* 
    left: 6.41%;
    right: 31.44%;
    top: 62.09%;
    bottom: 31.75%; */
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    padding: 10px;
    /* Gray 1 */

    color: #333333;

}

#lc1 p{
    /* left: 6.41%;
    right: 6.41%;
    top: 72.02%;
    bottom: 19.23%; */
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    padding: 10px;
    /* Gray 2 */

    color: #4F4F4F;
}

.bi-calendar3{
    font-size: 16px;
    color: #FFFFFF;
    font-weight: bold;
  
}

.bi-person-fill{
    font-size: 16px;
    color: #FFFFFF;
    font-weight: bold;

}

#cf1{
    background-color: #038AF6;
    height: 50px;
    /* padding-top: -200px; */
    overflow: hidden;
    /* opacity: 0.95; */

}

#cf2{
    background-color: #038AF6;
    height: 50px;
    /* padding-top: -200px; */
    overflow: hidden;
}

#cf3{
    background-color: #038AF6;
    height: 50px;
    /* padding-top: -200px; */
    overflow: hidden;
}

#cf4{
    background-color: #038AF6;
    height: 50px;
    /* padding-top: -200px; */
    overflow: hidden;
}

#lc2{
    width: 570px;
    height:490px;
    border-radius: 10px;
    transition: box-shadow .5s;
}
#lc2:hover{
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); 
    border-radius: 10px;
    border: none;
    clip-path: inset(-5px 0px 0px -15px);
   
}


#lc2 h5{
    /* 
        left: 6.41%;
        right: 31.44%;
        top: 62.09%;
        bottom: 31.75%; */
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    color: #333333;
    padding: 10px;
    
}
    
#lc2 p{
        /* left: 6.41%;
        right: 6.41%;
        top: 72.02%;
        bottom: 19.23%; */
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #4F4F4F;
    padding: 10px;
}

#lc3{
    width: 570px;
    height:490px;
    border-radius: 10px;
    transition: box-shadow .5s;
}

#lc3:hover{
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border-radius: 10px;
    border: none;
    clip-path: inset(-5px 0px 0px -15px);
   
}


#lc3 h5{
    /* 
        left: 6.41%;
        right: 31.44%;
        top: 62.09%;
        bottom: 31.75%; */
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    color: #333333;
    padding: 10px;
    
}
    
#lc3 p{
        /* left: 6.41%;
        right: 6.41%;
        top: 72.02%;
        bottom: 19.23%; */
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #4F4F4F;
    padding: 10px;
}

#lc4{
    width: 570px;
    height:490px;
    border-radius: 10px;
    transition: box-shadow .5s;
}



#lc4:hover{
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border-radius: 10px;
    border: none;
    clip-path: inset(-5px 0px 0px -15px);
   
}



#lc4 h5{
    /* 
        left: 6.41%;
        right: 31.44%;
        top: 62.09%;
        bottom: 31.75%; */
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    color: #333333;
    padding: 10px;
    
}
    
#lc4 p{
        /* left: 6.41%;
        right: 6.41%;
        top: 72.02%;
        bottom: 19.23%; */
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #4F4F4F;
    padding: 10px;
}


/* -----------------------------------------------------Latest Footer--------------------------------------------- */

.latestFooter{
    margin-top: 130px;
}