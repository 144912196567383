#cnameErrors{
   
    color: rgb(255, 0, 0);
    width: 230px;
    font-weight: 400;
    margin-left: 0px;
   
}

#cemailErrors{
  
    color: rgb(255, 0, 0);
    width: 216px;
    font-weight: 400;
    margin-left: 0px;
    
}

#csubjectErrors{
    
    color: rgb(255, 0, 0);
    width: 450px;
    font-weight: 400;
    margin-left: 0px;
   
}

/* #commentErrors{
    position: absolute;
    color: rgb(235, 31, 31);
    width: 450px;
    font-weight: 400;
    z-index: 1;
    margin-top: 370px;
} */

/* #successMessage{
    color: rgb(10, 252, 10);
    font-weight: 400;
    font-size: 20px; 
} */




.forSwal {
    height: 250px;
  
}


/* ------------------------------------------Section 1-------------------------------------------- */
#csection1 img{
    position: absolute;
    height: 380px;
    left: 0px;
    top: 235px;
    width: 100%;
    overflow-x: hidden;
    
}

#csec1 h1{
   
     /* width: 332px; */
    /*height: 89px; */
    /* left: 804px; */
    position: absolute;
    margin-left: 100px;
    top:300px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    line-height: 89px;
    /* identical to box height */
    
    /* background-color: aqua; */
    color: #FFFFFF;
   
    
}


#chomeTocovid h6{
    position: absolute;
    /* width: 250px; */
    /*height: 22px; */
    /* left: 891.5px; */
    top: 404px;
    margin-left: -20px;
    font-family: Nunito;
    font-style: normal;
   
    font-size: 17px;
    line-height: 20px;
    /* identical to box height */

    
    color: #FFFFFF;
    overflow: hidden;
}

#chomeTocovid h6{
    overflow: hidden;
}

#chomeTocovid span{
    opacity: 0.6;
}


/* -----------------------------------------------Contact Card------------------------------------- */

#contactCard{
    /* width: 366px; */
    height: 473px;
    /* left: 327px; */
    margin-top: 251px;
   
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
}


#form4contact{
    padding: 20px;
    margin-top: 60px;
    
}


#contactText{
    background-image: url(Group898.jpg);
    background-size: 463px;
    background-repeat: no-repeat;
    padding-left: 4%;
    height: 471.3px;
    margin-left: 0px;
   
}

#contactText img{
    width: 80%;
    height: 70%;
    
    
}

#ps1{
    outline-color: black;
    box-shadow: none;
    background-color: #FFFFFF;
  
}


#cc5{
    width: 90%;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    padding-bottom: 60px;
    padding-top: 100px;
}

#cc6{
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
}

#rfpButton{
    padding: 14px 15px;
    position: absolute;
    /* width: 132px;
    height: 49px; */
    /* left: 436.12px; */
    /* top: 1067px; */
    font-weight: bold;
    font-size: 14px;
    background: #038AF6;
    border-radius: 5px;
}


/* --------------------------------------Map------------------------------------------- */
#map{
    margin-top: 100px;
}

/* ---------------------------------------------Shield Footer-------------------------------------------- */

.shieldFooter{
    margin-top: 00px;
}