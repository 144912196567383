/* ------------------------------------Section 1--------------------------------------- */
#careerssection1 img{
    position: absolute;
    height: 380px;
    left: 0px;
    top: 235px;
    width: 100%;
    overflow-x: hidden;
    
}

#careerssec1 h1{
   
     /* width: 332px; */
    /*height: 89px; */
    /* left: 804px; */
    position: absolute;
    /* margin-left: 100px; */
    top:350px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    line-height: 89px;
    /* identical to box height */
    
    /* background-color: aqua; */
    color: #FFFFFF;
   
    
}


#careershomeTocovid h6{
    position: absolute;
    /* width: 250px; */
    /*height: 22px; */
    /* left: 891.5px; */
    top: 474px;
    margin-left: -20px;
    font-family: Nunito;
    font-style: normal;
   
    font-size: 17px;
    line-height: 20px;
    /* identical to box height */

    
    color: #FFFFFF;
    overflow: hidden;
}

#careershomeTocovid h6{
    overflow: hidden;
}

#careershomeTocovid span{
    opacity: 0.6;
}

/* ----------------------------------------------Positions----------------------------------------------- */
#openPositions h2{
   
    margin-top: 528px;
    font-weight: 700;
    font-size: 35px;
    line-height: 48px;
    /* identical to box height */

    text-align: center;

    /* Gray 1 */

    color: #333333;
}


#positionsRow{
    margin-top: 70px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    
    /* 1 */

    color: #828282;
}

#positionsRow li{
   
    padding-left: 50px;
}

#positionsRow li:after {
    content: '';
    display: block;
    margin-top: 9px;
    height: 3px;
    width: 0px;
    background: #038AF6;
    transition: width .5s ease, background-color .5s ease;

}

#positionsRow li:hover:after {
    width: 100%;
    background: #038AF6;
}

#positionsRow li:hover{
    color: #038AF6;
}

#positionsRow:active{
    color:#038AF6;
}


.line4positions{

    width: 70%;
    /* top: 864px; */
    
    /* Gray 5 */

    border: 1px solid #E0E0E0;
}

#pc1{
   
    width: 345px;
    height: 156px;
    border-left: 12px solid #038AF6;
    margin-top: 34px;
    margin-right: 10px;
    /* White */
    margin-left: 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

#pc1 h5{
    margin-top: 25px;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;

    /* Gray 1 */

    color: #333333;
}

#pc1 p{
   
    margin-top: 25px;
   
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */


    /* Gray 1 */

    color: #333333;

}

#loc{
    padding-right: 10px;
}


/* ------------------------------------------------Careers Footer------------------------------------------- */
.careersFooter{
    margin-top: 100px;
}