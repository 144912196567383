#anameErrors{
   
    color: rgb(255, 0, 0);
    width: 216px;
    font-weight: 400;
    margin-left: -10px;
   
}

#aemailErrors{
  
    color: rgb(255, 0, 0);
    width: 216px;
    font-weight: 400;
    margin-left: -20px;
    
}

#asubjectErrors{
    
    color: rgb(255, 0, 0);
    width: 450px;
    font-weight: 400;
    margin-left: -140px;
   
}

/* #commentErrors{
    position: absolute;
    color: rgb(235, 31, 31);
    width: 450px;
    font-weight: 400;
    z-index: 1;
    margin-top: 370px;
} */

/* #successMessage{
    color: rgb(10, 252, 10);
    font-weight: 400;
    font-size: 20px; 
} */




.forSwal {
    height: 250px;
  
}


/* -----------------------------------------Section 1------------------------------------- */
#aboutsection1 img{
    position: absolute;
    height: 380px;
    left: 0px;
    top: 235px;
    width: 100%;
    overflow-x: hidden;
    
}

#aboutsec1 h1{
   
     /* width: 332px; */
    /*height: 89px; */
    /* left: 804px; */
    position: absolute;
    margin-left: 100px;
    top:310px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    line-height: 89px;
    /* identical to box height */
    
    /* background-color: aqua; */
    color: #FFFFFF;
   
    
}

#aboutsec2 h4{
    position: absolute;
    /* width: 380px;
    height: 34px; */
    /* left: 770px; */
    top: 410px;
    margin-left: 50px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 34px;
    /* identical to box height */


    color: #FFFFFF;
}

#abouthomeTocovid h6{
    position: absolute;
    /* width: 250px; */
    /*height: 22px; */
    /* left: 891.5px; */
    top: 474px;
    margin-left: -20px;
    font-family: Nunito;
    font-style: normal;
   
    font-size: 17px;
    line-height: 20px;
    /* identical to box height */

    
    color: #FFFFFF;
    overflow: hidden;
}

#abouthomeTocovid h6{
    overflow: hidden;
}

#abouthomeTocovid span{
    opacity: 0.6;
}



/* ----------------------------------------Bar-------------------------------------------- */
#bar{
    /* position: absolute; */
    width:100%;
    height: 122px;
    left: 0px;
    margin-top: 380px;
    /* White */
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    
}

#barRow{
    width: 99%;
    height: 100%;
    align-items: center;
    justify-content: center;
}


#barDivider1{
    /* position: absolute; */
    width: 78px;
    height: 0px;
    margin-left: 140px;
    margin-top: -20px;

    /* Gray 5 */

    border: 1px solid #E0E0E0;
    transform: rotate(90deg);
}

#barDivider2{
    /* position: absolute; */
    width: 78px;
    height: 0px;
    
    margin-left: 200px;
    margin-top: -20px;

    /* Gray 5 */

    border: 1px solid #E0E0E0;
    transform: rotate(90deg);
}

#barDivider3{
    /* position: absolute; */
    width: 78px;
    height: 0px;
    
    left: 840px;
    top: 60px;

    /* Gray 5 */

    border: 1px solid #E0E0E0;
    transform: rotate(90deg);
}


#barDivider4{
    /* position: absolute; */
    width: 78px;
    height: 0px;
    
    left: 1120px;
    top: 60px;

    /* Gray 5 */

    border: 1px solid #E0E0E0;
    transform: rotate(90deg);
}




#b1 h5{
    overflow: hidden;
    font-weight: 600;
}


/* -------------------------------------Number 1------------------------------------------- */ 

#digits{
    position: absolute; 
    left: 400.2px;
    top: 836px;
    
    font-size: 300px;
    /* 1 */
    color: #038AF6;
    opacity: 0.05;
    overflow: hidden;
}

#number1{
    /* position: absolute; */
    margin-top: 120px;
}

#n1{
    position: static;
    /* width: 388.14px; */
    height: 48px;
    left: 0px;
    top: 0px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 48px;
    /* identical to box height */
    /* Gray 1 */
    color: #333333;
    /* Inside auto layout */
    
}

#n2{
    position: static;
    width: 122px;
    height: 26px;
    left: 0px;
    top: 0px;
    font-weight: 600;
    font-size: 19px;
    line-height: 26px;
    /* identical to box height */
    /* Gray 2 */
    color: #4F4F4F;
    /* Inside auto layout */
}
#n3{
    position: static;
    /* width: 262px; */
    height: 26px;
    left: 0px;
    top: 40px;

    font-weight: 600;
    font-size: 19px;
    line-height: 26px;
    /* identical to box height */
    /* Gray 2 */

    color: #4F4F4F;
}
#n4{
    position: static;
    /* width: 544px; */
    height: 50px;
    left: 0px;
    top: 0px;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;

    /* Gray 1 */
    color: #333333;
    
}
#n5-1{
   
    /* width: 459px; */
    
    
    
    font-weight: 600;
    font-size: 16px;
    line-height: 35px;
    /* identical to box height */

  
    /* Gray 2 */

    color: #4F4F4F;

}




#n5-2{
   
    /* width: 459px; */
    
    
    
    font-weight: 600;
    font-size: 16px;
    line-height: 35px;
    /* identical to box height */

    text-decoration: block;
    /* Gray 2 */

    color: #4F4F4F;
}

#n5-3{
   
    /* width: 459px; */
    
    
    
    font-weight: 600;
    font-size: 16px;
    line-height: 35px;
    /* identical to box height */

    text-decoration: block;
    /* Gray 2 */

    color: #4F4F4F;
}

#n5-4{
   
    /* width: 459px; */
    
    
    
    font-weight: 600;
    font-size: 16px;
    line-height: 35px;
    /* identical to box height */

    text-decoration: block;
    /* Gray 2 */

    color: #4F4F4F;
}

#n5-5{
   
    /* width: 459px; */
    
    
    
    font-weight: 600;
    font-size: 16px;
    line-height: 35px;
    /* identical to box height */

    text-decoration: block;
    /* Gray 2 */

    color: #4F4F4F;
}

#meeting img{
    width: 453px;
    height: 433px;
    top: 835px;
}


/* ---------------------------------------Number 2--------------------------------------- */

#n22{
    overflow-x: hidden;
}

#digits2{
    /* position: absolute; */
    margin-left: 840px;
    /* top: 1736px; */
    
    font-size: 300px;
    /* 1 */
    color: #f66403;
    opacity: 10.05;
    z-index: 99999;
  
}

#number2{
    /* position: absolute; */
    /* width: 1345.7px; */
    width: 65%;
    height: 682px;
    /* left: 574.3px; */
    margin-top: 155px;
    /* 1 */
    background: #038AF6;
   
}

#phoneImg{
    position: absolute;
    width: 500.09px;
    height: 588px;
    left: 150.68px;
    top: 1505px;
    border-radius: 20px;
    z-index: 1;
}

#number2 h2{
   
    width: 388.14px;
    height: 48px;
    margin-left: 240px;
    margin-top: 130px;
    font-weight: bold;
    font-size: 35px;
    line-height: 48px;
    /* identical to box height */
    /* White */
    color: #FFFFFF;
    z-index: 1;
}


#number2 p{
    
    width: 600px;
   
    margin-left: 240px;
    top: 230px;
    
    font-size: 16px;
    line-height: 30px;
    /* White */
    color: #FFFFFF;
    z-index: 1;
}


/* ---------------------------------------Number 3---------------------------------------- */
#n3bg{
    /* position: absolute; */
    width: 110%;
    height: 676px;
    left: 0px;
    margin-top: 110px;
    overflow: hidden;
}

#n3phones{
    position: absolute;
    /* width: 675px; */
    height: 676px;
    
    top: 2250px;
}

#digits3{
    position: absolute;
   
    
    font-size: 300px;
    top: 2368.01px;
    margin: 10%;
    /* 1 */
    color: #038AF6;
    
    opacity: 0.05;
    overflow: hidden;
}


#n3heading{
    position: absolute;
    height: 48px;
    top: 2353px;
    font-weight: bold;
    font-size: 35px;
    line-height: 48px;
    /* identical to box height */
    /* Gray 1 */
    color: #333333;
}

#n3p{
    position: absolute;
    width: 600px;
   
    top: 2441px;
    
    font-size: 18px;
    line-height: 55px;
    /* Gray 2 */
    color: #4F4F4F;
}



/* -----------------------------------------Number 4---------------------------------------------- */
#digits4{
    /* position: absolute; */
    margin-left: 50%;
    margin-top: 180px;
    font-size: 300px;
    /* 1 */

    color: #038AF6;
    opacity: 0.05;
    overflow: hidden;
}

#number4img{
    position: absolute;
    width: 404px;
    height: 404px;
    
    top: 3136.02px;
}

#n4heading{
    position: absolute;
    width: 603px;
    height: 96px;
    top: 3228.02px;
    font-weight: bold;
    font-size: 35px;
    line-height: 48px;

    /* Gray 1 */

    color: #333333;

}

#n4p{
    position: absolute;
    width: 253px;
    top: 3363.02px;
    font-weight: 600;
    font-size: 18px;
    line-height: 50px;

    /* Gray 2 */

    color: #4F4F4F;
}

/* -----------------------------------------------Number 5----------------------------------------- */

#goals{
    /* position: absolute; */
    
    margin-top: 100px;
    text-align: center;
   
    /* Gray 1 */
    color: black;
   
    
    
}

#goals h2{
    font-weight: bold;
    font-size: 70px;
    line-height: 60px;
    overflow: hidden;
}


#n5bg{
   
    position: absolute;
    height: 546px;
    top: 3680px;
    background-color: #a4bfd1;   
}

#goalsDetail {
    /* position: absolute; */
    
    
    margin-top: 7%;
    
}

#goalsDetail h5{
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    margin-top: 20px;
}

#goalsDetail p{
    font-size: 14px;
}


/* -------------------------------------------Number 6------------------------------------------- */
#n6h2{
   
    margin-top: 850px;
    font-weight: bold;
    font-size: 45px;
    line-height: 61px;

}

#n6r1{
    /* position: absolute; */
    margin-top: 50px;
    
   
}

/* #n6r1 p{
    width: 1279.84px;
} */

#n6r1 h4{
  
    font-weight: bold;
    font-size: 27px;
    line-height: 41px;
}

#n6r1 h6{
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    color: #038AF6;
}

#n6r2{
    /* position: absolute; */
    margin-top: 58px;
   
}

#n6r2 h4{
  
    font-weight: bold;
    font-size: 27px;
    line-height: 41px;
}

#n6r2 h6{
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    color: #038AF6;
}

#protect{
    padding-left: 10px;
}

/* ------------------------------------------------Number 7------------------------------------------------- */

#n7bg{
    /* position: absolute; */
    height: 594px;
    left: 0px;
    margin-top: 100px;
    background: #038AF6;
    text-align: center;
}

#formDesc{
    /* position: absolute; */
    margin-top: 70px;
    font-weight: 800;
    font-size: 23px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
}

#formDesc h3{
    height: 40px;
    font-weight: bold;
}

 #aboutEmail{
    margin-left: -12px;
    width: 240px;
}

#aboutName{
    width: 233px;
}

#form1{
    margin-top: 60px;
    border-radius: 5px;
    padding-bottom: 13px;
   
}

#form2{
    margin-top: 0px;
    border-radius: 5px;
    padding-bottom: 13px;
    
}


#form3{
 
    /* top: 400px; */
    border-radius: 5px;
    padding-bottom: 10px;
   
}




#form3-1{
    width: 480px;
    height: 100px;
    margin-left: 190px;
}

#form4{
    /* top: 450px; */
    border-radius: 5px;
    /* margin-left: 90px; */
    margin-right: 10px;
}

#form4-1{
    padding: 13px 17px;
    /* White */

    background: #FFFFFF;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-transform: uppercase;
    color: #333333;
}

/* --------------------------------------------------About Footer---------------------------------- */

.aboutFooter{
    margin-top: 0px;
}


