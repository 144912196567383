/* -------------------------------------------Section 1--------------------------------------------- */
#asection1 img{
    position: absolute;
    height: 380px;
    left: 0px;
    top: 237px;
    width: 100%;
    overflow-x: hidden;
    
}

#asec1 h1{
   
     /* width: 332px; */
    /*height: 89px; */
    /* left: 804px; */
    position: absolute;
    margin-left: 0px;
    top:350px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    line-height: 89px;
    /* identical to box height */
    
    /* background-color: aqua; */
    color: #FFFFFF;
}




#ahomeTocovid h6{
    position: absolute;
    /* width: 250px; */
    /*height: 22px; */
    /* left: 891.5px; */
    top: 474px;
    margin-left: 0px;
    font-family: Nunito;
    font-style: normal;
   
    font-size: 17px;
    line-height: 20px;
    /* identical to box height */

    
    color: #FFFFFF;
    overflow: hidden;
}

#ahomeTocovid h6{
    overflow: hidden;
}

#ahomeTocovid span{
    opacity: 0.6;
}


/* ------------------------------------------------------Privacy Text------------------------------------------- */

#cont{
    position: absolute;
    top: 700px;
   overflow: hidden;
    margin: 0% 13%;
    line-height: 30px;
    font-weight: 600;
    font-size: 16px;
   
}

#cont h4{
    overflow: hidden;
    font-weight: 700;
}

#cont p{
    font-weight: 600;
}

#cont h1{
    overflow: hidden;
    font-weight: 700;
    font-size: 55px;
}

#cont h5{
    overflow: hidden;
    font-weight: 600;
}

#cont li{
    font-weight: 500;
}

/* ----------------------------------------------Policy Footer------------------------------------------------ */

.policyFooter{
    margin-top: 3100px;
}

