/* -----------------------------------------------Section 1---------------------------------------- */
#techsection1 img{
    position: absolute;
    height: 380px;
    left: 0px;
    top: 235px;
    width: 100%;
    overflow-x: hidden;
    
}

#techsec1 h1{
   
     /* width: 332px; */
    /*height: 89px; */
    /* left: 804px; */
    position: absolute;
    margin-left: 100px;
    top:350px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    line-height: 89px;
    /* identical to box height */
    
    /* background-color: aqua; */
    color: #FFFFFF;
   
    
}


#techhomeTocovid h6{
    position: absolute;
    /* width: 250px; */
    /*height: 22px; */
    /* left: 891.5px; */
    top: 474px;
    margin-left: -20px;
    font-family: Nunito;
    font-style: normal;
   
    font-size: 17px;
    line-height: 20px;
    /* identical to box height */

    
    color: #FFFFFF;
    overflow: hidden;
}

#techhomeTocovid h6{
    overflow: hidden;
}

#techhomeTocovid span{
    opacity: 0.6;
}


/* -----------------------------------------------------Doctors Section---------------------------------------- */   

#doctors{
    background: #F9F9F9;

}

#doctorsImg{
    
    /* position: absolute; */
    height: 550px;
    margin-left: -20px;
    margin-top: 378px;
}


#doctorsText{
  
    height: 930px;
    /* padding-left: 5%; */
    top: 746px;
    color: #333333;
    background: #F9F9F9;
}

#actualText h2{
    /* position: absolute; */
    width: 460px;
    /* left: 1089px; */
    margin-top: 450px;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;

    /* Gray 1 */

    color: #333333;
}

#actualText p{
    /* position: absolute; */
    width: 504px;
    margin-top: 30px;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: #828282;
}


/* -------------------------------------------------Digital Section--------------------------------------------------- */

#digitalHeading{
    /* position: absolute; */
    margin-top: 162px;
    
    text-align: center;

    color: #333333;
}

#digitalHeading h2{
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
}


#digitalPara{
    /* position: absolute; */
    margin-top: 80px;
    font-weight: 600;
    font-size: 15px;
    line-height: 42px;
    text-align: center;

    /* Gray 3 */

    color: #828282;
}

#allCards{
    width: 1000px;
    height: 350px;
    margin-top: 146px;
}


#aiCard{
    /* position: absolute; */
    
    background: #038AF6;
    border-radius: 10px 0px 0px 10px;
}

#aiCard h5{
    font-weight: bold;
    font-size: 30px;
    line-height: 48px;
    margin-left:-5px;
    color: #FFFFFF;
}

#aiCard p{
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #FFFFFF;

}


#dltCard{
    /* position: absolute; */
    /* width: 322px;
    height: 316px; */
    /* left: 578px; */
    /* top: 1676px; */

    /* 1 */

    background: #038AF6;
    opacity: 0.9;
}

#dltCard p{
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #FFFFFF;

}

#dltCard h5{
    font-weight: bold;
    font-size: 30px;
    line-height: 48px;
    color: #FFFFFF;
}

#ontologiesCard{
    /* position: absolute; */
    /* width: 322px;
    height: 316px; */
    /* left: 900px; */
    /*top: 1676px; */

    /* 1 */

    background: #038AF6;
    opacity: 0.8;
    border-radius: 0px 10px 10px 0px;
}

#ontologiesCard h5{
    font-weight: bold;
    font-size: 30px;
    line-height: 48px;
    margin-top: 18px;
    color: #FFFFFF;
}


#ontologiesCard p{
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #FFFFFF;

}


#aiSvg{
    width: 100px;
    margin-left: 34%;
    margin-top: 15%;
    padding-bottom: 2%;
}


/* -------------------------------------------------Tech Footer-------------------------------------- */
.techFooter{
    margin-top: 100px;
}

