/* -----------------------------------------------Main Section------------------------------------- */

#mainSection{
    position: absolute;
    height: 380px;
    left: 0px;
    top: 235px;
    width: 100%;
    overflow-x: hidden;
    
}

#sec1 h1{
   
     /* width: 332px; */
    /*height: 89px; */
    /* left: 804px; */
    position: absolute;
    margin-left: 100px;
    top:350px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    line-height: 89px;
    /* identical to box height */
    
    /* background-color: aqua; */
    color: #FFFFFF;
   
    
}


#homeTocovid h6{
    position: absolute;
    /* width: 250px; */
    /*height: 22px; */
    /* left: 891.5px; */
    top: 474px;
    margin-left: 0px;
    font-family: Nunito;
    font-style: normal;
    text-align: center;
    font-size: 17px;
    line-height: 20px;
    /* identical to box height */

    
    color: #FFFFFF;
    overflow: hidden;
}

#homeTocovid h6{
    overflow: hidden;
}

#homeTocovid span{
    opacity: 0.6;
}



/* ----------------------------------------------Pic Only------------------------------------------ */
#picOnly{
  
    width: 200%;
    height: 720.13px; 
    /* left: 327px; */
    margin-top: 500px;
   
    
}

/* ---------------------------------------------Section 3------------------------------------------ */
#part1{
  
    /* width:40%; */
    height: 568px;
    left: 0px;
       
    margin-top: 136px;
    /* Gray 7 */
    background: #F9F9F9;
    overflow: hidden;
    
    /* z-index: 1; */
}

.leftSide{
    padding-top: 10%;
    padding-bottom: 10%;
    padding-left: 35%;
    padding-right: 5%;
   
}



#part1 h3{
  
    width: 388.14px;
   
    /* left: 354.36px; */
    /* top: 1917px; */
    /* left: 40%; */
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 33px;
    line-height: 48px;

    /* Gray 1 */

    color: #333333;
    margin-right: 0%;
    
}

#part1 p{
    
    width: 388.14px;
   
    /* left: 354.36px; */
    top: 220px;
    /* left: 40%; */
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;

    /* Gray 3 */

    color: #828282;
    
}

#part1 h6{
   
    width: 187px;
  
    /* left: 354.36px; */
    top: 300px;
    /* left: 40%; */
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */


    /* 1 */

    color: #038AF6;
}

#rp1{
  
   
    top: 330px;
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    
    /* Gray 1 */
    color: #333333;
   
    
    /* left: 40%; */
}

#rp2{
   
   
        
    top: 380px;
    font-weight: 600;
    font-size: 15px;
    line-height: 35px;
    
    /* Gray 1 */
    color: #333333;
    
    /* left: 40%; */
}

#rp3{
   
   
        
    top: 410px;
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
    
    /* Gray 1 */
    color: #333333;
    
    /* left: 40%; */
}

#rp4{
   
    width: 369.11px;
    height: 220px;
        
    top: 440px;
    font-weight: 600;
    font-size: 15px;
    line-height: 35px;
    
    /* Gray 1 */
    color: #333333;
    
    /* left: 40%; */
}


  
#rp1::before {
    content: " ";
    color: red;
    font-weight: bold;
    display: inline-block; 
    width: 1px;
    
}


.rightSide{
    padding-top: 7%;
    padding-bottom: 10%;
    padding-left: 5%;
}

#part2{
   
    /* width:60%; */
    height: 634px;
    left: 41.7%;
    margin-top: 136px;

    /* 1 */
    /* float: inline-end; */
    background: #038AF6;
    overflow: hidden;
    /* z-index: -1; */
}

#part2 h3{
  
    width: 596px;
    height: 61px;
    /* left: 807.09px; */
    /* top: 1917px; */
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 61px;

    /* White */

    color: #FFFFFF;
}

#p2p1{
   
    width: 600.91px;
    height: 118px;
    /* left: 807.09px; */
    top: 145px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    text-align: justify;
    /* White */
    color: #FFFFFF;
}

#r1{
   
    width: 524px;
   
    /* left: 826.12px; */
    top: 258px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height */
    /* White */
    color: #FFFFFF;
}

#r2{
   
    width: 524px;
 
    /* left: 826.12px; */
    top: 288px;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height */
    /* White */
    color: #FFFFFF;
}

#r3{
  
    width: 524px;
    
    /* left: 826.12px; */
    top: 318px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height */
    /* White */
    color: #FFFFFF;
}

#r4{
   
    width: 524px;
   
    /* left: 826.12px; */
    top: 348px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height */
    /* White */
    color: #FFFFFF;
}

#r5{
   
    width: 524px;
  
    /* left: 826.12px; */
    top: 378px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height */
    /* White */
    color: #FFFFFF;
}

#r6{
   
    width: 524px;
  
    /* left: 826.12px; */
    top: 428px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height */
    /* White */
    color: #FFFFFF;
}


#p2p2{
   
    /* width: 408.85px; */
   
    /* left: 807.09px; */
    top: 480px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    /* White */
    color: #FFFFFF;
}

#nestp2 h4{
    position: absolute;
    width: 255px;
   
    margin-left: 460.69px;
    margin-top: -135px;

    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 41px;
    /* identical to box height */


    /* White */

    color: #FFFFFF;

}

#nestp2 p{
    position: absolute;
    width: 169.31px;
   
    margin-left: 460.69px;
    margin-top: -90px;

    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;

    /* White */

    color: #FFFFFF;
}

/* ---------------------------------------------Cards-------------------------------------------- */

#cardRow1{
   
    margin-top: 200px;
    
    
}

#cardRow1 h2{
    font-weight: bold;
    font-size: 35px;
    line-height: 48px;
    /* identical to box height */
    color: #333333;
}

#cardRow1 p{

    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    /* Gray 3 */
    color: #828282;
}

#blueCard1:hover{
    
    box-shadow: 3px 3px 40px 6px rgba(0, 0, 0, 0.247);
    /* transition: width 10s ease, background-color 10s ease; */
}

#blueCard1{
    /* position: absolute; */
    
    height: 305px;
    /* left: 0%;
    right: 0%; */
    margin-top:100px;
    margin-left: 30px;
    /* bottom: 0%; */
    /* 1 */
    background-image: url("covidcardbg.jpg");
    background-size: auto;
    border-radius: 10px;
}

#blueCard1 h5{
    position: absolute;
    left: 8.9%;
    /* right: 78.95%; */
    top: 8.47%;
    bottom: 73.55%;
    font-weight: 600;
    font-size: 33px;
    line-height: 55px;
    /* identical to box height */
    text-transform: capitalize;
    /* White */
    color: #FFFFFF;

}


#blueCard1 p{
    position: absolute;
    height: 100px;
    left: 8.9%;
    right: 8.9%;
    top: 39.09%;
    bottom: 34.88%; 
    font-weight: bold;
    font-size: 20px;
    line-height: 31px;
    text-transform: capitalize;
    /* White */
    color: #FFFFFF;
}

#blueCard1 a{
    position: absolute;
    left: 8.9%;
    right: 60.58%;
    top: 84.3%;
    bottom: 4.43%;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    /* identical to box height */

    text-transform: capitalize;
    text-decoration: none;
    /* White */

    color: #FFFFFF;
}

#blueCard2:hover{
    
    box-shadow: 3px 3px 40px 6px rgba(0, 0, 0, 0.247);
    /* transition: width 10s ease, background-color 10s ease; */
}

#blueCard2{
    /* position: absolute; */
   
    height: 305px;
    /* left: 0%;
    right: 0%; */
    margin-top:40px;
    margin-left: 30px;
    /* bottom: 0%; */
    /* 1 */
    background-image: url("covidcardbg.jpg");
    border-radius: 10px;
}

#blueCard2 h5{
    position: absolute;
    left: 8.9%;
    /* right: 78.95%; */
    top: 8.47%;
    bottom: 73.55%;
    font-weight: 600;
    font-size: 33px;
    line-height: 55px;
    /* identical to box height */
    text-transform: capitalize;
    /* White */
    color: #FFFFFF;
}

#blueCard2 p{
    position: absolute;
    height: 100px;
    left: 8.9%;
    right: 8.9%;
    top: 39.09%;
    bottom: 34.88%; 
    font-weight: bold;
    font-size: 20px;
    line-height: 31px;
    text-transform: capitalize;
    /* White */
    color: #FFFFFF;
}

#blueCard2 a{
    position: absolute;
    left: 8.9%;
    right: 60.58%;
    top: 84.3%;
    bottom: 4.43%;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    /* identical to box height */

    text-transform: capitalize;
    text-decoration: none;
    /* White */

    color: #FFFFFF;
}


/* -----------------------------------------------------covidFooter------------------------------------------- */

.covidFooter{
    margin-top: 80px;
}
