#fullFooter{
    /* position: absolute; */
    width: 100%;
    height: 500px;
    left: 0%;
    right:0%;
    bottom: 0%;
    /* Gray 7*/ 
    background: #F9F9F9;
    /* top: 4226px; */
    padding-top: 120px;
    /* padding-left: 40px; */
    overflow: hidden;
    
}
/* 
.logo4footer{
   /* position: absolute; */
    /* left: 240px; 
} */

.logo4footer img{
    
    width: 270px;
}

.footerText{
    
    width: 380px;
    height: 104px;
    /* left: 240px; */
    
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    /* Gray 2 */
    color: #4F4F4F;
}

#quickLinks{
    
    padding: 0px;
  
    /* width: 140px; */
    height: 270px;
    /* left: 690px; */
    bottom: 4.26%;
    top: 22.36%;
    font-size: 14px;
    padding-left: 50px ;
    font-weight: bold;
}

#quickLinks p:hover{
    color: #038AF6;
    cursor: pointer;
}

#quickLinks h5{
    padding-bottom: 30px;
    color: #038AF6;
    font-weight: 800;
    width: 155px;
}

#services{
   
    padding: 0px;
  
    /* width: 165px; */
    height: 235px;
    /* left: 920.5px; */
    bottom: 7.26%;
    top: 22.36%;
    font-size: 14px;
    padding-left: 30px ;
    font-weight: bold;
}

#services h5{
    padding-bottom: 30px;
    color: #038AF6;
    font-weight: 800;
}

#services p{
    width: 165px;
}

#services p:hover{
    color: #038AF6;
    cursor: pointer;
    
}

#getinTouch{
  
    padding: 0px;
    
    height: 239px;
    /* margin-left: 1120px; */
    bottom: 0.26%;
    top: 22.36%;
    width: 205.01px;
    font-size: 14px;
    font-weight: bold;
    cursor: context-menu;
}

#getinTouch h5{
    padding-bottom: 30px;
    color: #038AF6;
    font-weight: 800;
}


.footerDivider{
   
    width: 100%;
    position: absolute;
    height: 10px;
    left: 0px;
    margin-top: 50px;
    /* top: 480px; */
    /* Gray 4 */
    border: 1px solid #BDBDBD;
}

#copyRight{
    padding-left: 40px;
    /* width: 323px; */
    
    /* left: 240px; */
    padding-top: 70px;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    /* identical to box height */
    /* Gray 2 */
    color: #4F4F4F;
}

#footerIcons{
   
    padding-left: 200px;
    padding-top: 70px;
    /* width: 161.31px; */
   
    /* left: 1170px; */
    bottom: 0px;
    cursor: pointer;
}

#footerMB{
    margin-right: 5px;
}

#footerEN{
    margin-right: 8px;
}

#footerLN{
    margin-right: 7px;
}

#footerCU{
    margin-right: 8px;
    color:#038AF6;
}

/* #footerFB{
    color: #4F4F4F;
    margin-right: 20px;
}

#footerIG{
    color: #4F4F4F;
    margin-right: 20px;
} */

#footerTW{
    font-size: 20px;
    color: #4F4F4F;
    
}

#footerPT{
    font-size: 20px;
    color: #4F4F4F;
    margin-right: 40px;
    margin-left: 40px;
    
}

#CA{
    margin-left: 20px;
}


.bi-mailbox2{
    color: #038AF6;
}

.bi-envelope-fill{
    color: #038AF6;
}

.bi-geo-alt-fill{
    color: #038AF6;
}

.flinks{
    text-decoration: none;
    color: black;
}

.flinks:hover{
    color: #038AF6;
}

.flinks-active{
    text-decoration: none;
    color:#038AF6;
   
}

