#header{
    /* position: fixed; */
    width: 100%;
    height: 238.26px;
    left: 0px;
    top: 0px;
    /* z-index: 99999; */
    background-color: #FFFFFF;
    overflow: hidden;
}


#row1{
    width: 102%;
    height: 48px;
    left: 0px;
    top: 0px;
    /* 1 */
    background: #038AF6;
    padding-top: 8px;
    padding-left: 40px;
    /* margin-left: -30px; */
    
    overflow: hidden;
    
}

#address{
    /* width: 400px; */
    left: 0px;
    /* margin-top: 2px; */
    /* Inside auto layout */
    font-weight: bold;
    font-size: 14px;
    /* margin-right: 150px; */
    margin-top: 2px;
    /* identical to box height */
    /* White */
    color: #FFFFFF;    
    /* background-color: aqua; */
}

#help{
   
    width: 239.67px;
    /* left: 186.73px; */
    
    /* Inside auto layout */
    font-weight: bold;
    font-size: 14px;
    /* background-color: bisque; */
    
    /* identical to box height */
    /* White */
    color: #FFFFFF;
    /* margin-right: 20px; */
}

#PO{    
    
    width: 160.73px; 
    
    
    
    /* Inside auto layout */
     /* background-color: rgb(29, 37, 37);  */
    font-weight: bold;
    font-size: 14px;
    
    /* identical to box height */
    /* White */
    color: #FFFFFF;
}

#mailbox1{
    margin-right:12px;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
}
#envelope1{
    margin-right:12px;
    font-size: 19px;
    font-weight: bold;
    color: #FFFFFF;
}
#location1{
    margin-right:12px;
    font-size: 17px;
    font-weight: bold;
    color: #FFFFFF;
}

#icons{
    
    width: 181.31px;
    height:40px;
    padding-left: 50px;
   
    font-weight: normal;
    cursor: pointer;
    /* background-color: #c1f603; */

}

/* #fb1{
    width: 16.31px;
    font-size: 16px;
    margin-left: -8px;
    
    color: #FFFFFF;
}

#ig1{
    width: 16.31px;
    font-size: 16px;
    margin-left: 30px;
   
    color: #FFFFFF;
} */
#tw1{
    /* width: 15.31px; */
    font-size: 20px;
    margin-left: 30px;
   
    color: #FFFFFF;
}
#pt1{
    /* width: 16.31px; */
    font-size: 20px;
    margin-left: 30px;
   
    color: #FFFFFF;
}

#row2{
    /* position: absolute; */
    width: 100%;
    height: 180.26px;
    left: 0px;
    margin-top: 15px;
    /* padding: 0 0% 0 0%; */
}

#logo{
    /* position: absolute; */
   
    margin-top: 1.0%;
    /* width: 320px; */
    height: 80px;
    /* margin-left: 170px; */
}


/* #search{
    /* position: absolute; 
    width: 570px;
    height: 47px;
    margin-left: 0px;
    
    margin-top: 30px;
    /* White 
    background: #FFFFFF;
    /* Gray 5 
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
    text-indent: 10px;

}

#searchButton{
    /* position: absolute; */
    /* width: 25px; 
    height: 30px;
    margin-left: -40px;
    margin-top: 5px;
    font-size: 17px;
    /* Gray 1 
    background-color:  #FFFFFF;
    border: none;
}    */

#cbutton{
    padding: 14px 15px;
    /* position: absolute; */
    /* width: 130px; */
    height: 47px;
    margin-left: 20px;
    margin-top: 30px;
    background: #038AF6;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    /* identical to box height */
    text-transform: uppercase;
    /* White */
    color: #FFFFFF;
    border:none;
    
}
#lbutton{
    padding: 14px 15px;
    /* position: absolute; */
    width: 100px;
    height: 47px;
    margin-left: 40px;
    margin-top: 30px;
    background: #ffffff;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    /* identical to box height */
    text-transform: uppercase;
    /* White */
    color: #038AF6;
    border: 1px solid #038AF6;
    
}


.lineforHeader{
    position: absolute;
    width: 100%;
    height: 0px;
    left: 0px;
    top: 168px;
    /* Gray 5 */
    border: 1px solid #E0E0E0;
}

#navLinks{
  
    /* position: absolute; */
    /* display: inline-block; */
    /* width: 100%; */
    height: 40px;
    /* left: 210px; */
    margin-top: -38.5px;
    /* White */ 
   
    cursor: pointer;
    margin-left: -30px;
    overflow: hidden;
    
    
}

#actualLinks li{
    
    padding-right: 40px;
   
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    
}

/* #actualLinks ul li{
    /* display: inline-block;
    
    padding-right: 40px;
    padding-bottom: 0px;
    height: 45px;
    left: 0px;
    top: 0px;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    /* text-transform: uppercase; */
    /* Gray 1 */
    /* color: #333333;
    
} */

#actualLinks li:after {
    content: '';
    display: block;
    margin-top: 9px;
    height: 3px;
    width: 0px;
    background: #038AF6;
    transition: width .5s ease, background-color .5s ease;

}

#actualLinks li:hover:after {
    width: 100%;
    background: #038AF6;
}

#actualLinks li:hover{
    color: #038AF6;
}


.links{
    text-decoration: none;
    color: black;
}


.links-active{
    text-decoration: none;
    color:#038AF6;
   
}

.links-active:after {
    
    content: '';
    display: block;
    margin-top: 9px;
    height: 3px;
    width: 100%;
    background: #038AF6;
    

}


#cars{
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    border: none;
    margin-top: -2px;
}

#cars option{
    display:none;
    
}

#cars option:hover{
    display:contents;
}


/* .LinksActive{
    color:#038AF6;
    text-decoration: none;
} */

/* .active, .Links:hover{
    color:#038AF6;
} */


/* ------------------------------------------------------------------------------------------------------------------------------ */

@media screen and (max-width:960px){
    
    #row1{
        width: 102%;
        height: 48px;
        left: 0px;
        top: 0px;
        /* 1 */
        background: #038AF6;
        padding-top: 8px;
        padding-left: 40px;
        /* margin-left: -30px; */
        
        overflow: hidden;
        
    }
    
    #address{
        /* width: 400px; */
        left: 0px;
        /* margin-top: 2px; */
        /* Inside auto layout */
        font-weight: bold;
        font-size: 14px;
        /* margin-right: 150px; */
        margin-top: 2px;
        /* identical to box height */
        /* White */
        color: #FFFFFF;    
        /* background-color: aqua; */
    }
    
    #help{
       
        width: 239.67px;
        /* left: 186.73px; */
        
        /* Inside auto layout */
        font-weight: bold;
        font-size: 14px;
        /* background-color: bisque; */
        
        /* identical to box height */
        /* White */
        color: #FFFFFF;
        /* margin-right: 20px; */
    }
    
    #PO{    
        
        width: 160.73px; 
        
        
        
        /* Inside auto layout */
         /* background-color: rgb(29, 37, 37);  */
        font-weight: bold;
        font-size: 14px;
        
        /* identical to box height */
        /* White */
        color: #FFFFFF;
    }
    
    #mailbox1{
        margin-right:12px;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
    }
    #envelope1{
        margin-right:12px;
        font-size: 19px;
        font-weight: bold;
        color: #FFFFFF;
    }
    #location1{
        margin-right:12px;
        font-size: 17px;
        font-weight: bold;
        color: #FFFFFF;
    }
    
    #icons{
        
        width: 181.31px;
        height:40px;
        padding-left: 50px;
       
        font-weight: normal;
        cursor: pointer;
        /* background-color: #c1f603; */
    
    }
    
    /* #fb1{
        width: 16.31px;
        font-size: 16px;
        margin-left: -8px;
        
        color: #FFFFFF;
    }
    
    #ig1{
        width: 16.31px;
        font-size: 16px;
        margin-left: 30px;
       
        color: #FFFFFF;
    } */
    #tw1{
        /* width: 15.31px; */
        font-size: 20px;
        margin-left: 30px;
       
        color: #FFFFFF;
    }
    #pt1{
        /* width: 16.31px; */
        font-size: 20px;
        margin-left: 30px;
       
        color: #FFFFFF;
    }
    
    #row2{
        /* position: absolute; */
        width: 100%;
        height: 180.26px;
        left: 0px;
        margin-top: 15px;
        /* padding: 0 0% 0 0%; */
    }
    
    #logo{
        /* position: absolute; */
       
        margin-top: 1.0%;
        width: 320px;
        height: 80px;
        margin-left: 170px;
    }
    
    
    #search{
        /* position: absolute; */
        width: 570px;
        height: 47px;
        margin-left: 0px;
        
        margin-top: 30px;
        /* White */
        background: #FFFFFF;
        /* Gray 5 */
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 14px;
        outline: none;
        text-indent: 10px;
    
    }
    
    #searchButton{
        /* position: absolute; */
        /* width: 25px; */
        height: 30px;
        margin-left: -40px;
        margin-top: 5px;
        font-size: 17px;
        /* Gray 1 */
        background-color:  #FFFFFF;
        border: none;
    }   
    
    #cbutton{
        padding: 14px 15px;
        /* position: absolute; */
        /* width: 130px; */
        height: 47px;
        margin-left: 0px;
        margin-top: 30px;
        background: #038AF6;
        border-radius: 5px;
        font-weight: bold;
        font-size: 14px;
        line-height: 15px;
        /* identical to box height */
        text-transform: uppercase;
        /* White */
        color: #FFFFFF;
        border:none;
        
    }
    
    
    .lineforHeader{
        position: absolute;
        width: 100%;
        height: 0px;
        left: 0px;
        top: 168px;
        /* Gray 5 */
        border: 1px solid #E0E0E0;
    }
    
    #navLinks{
      
        /* position: absolute; */
        /* display: inline-block; */
        /* width: 100%; */
        height: 40px;
        /* left: 210px; */
        margin-top: -38.5px;
        /* White */ 
       
        cursor: pointer;
        margin-left: -30px;
        overflow: hidden;
        
        
    }
    
    #actualLinks li{
        
        padding-right: 40px;
       
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        
    }
    
    /* #actualLinks ul li{
        /* display: inline-block;
        
        padding-right: 40px;
        padding-bottom: 0px;
        height: 45px;
        left: 0px;
        top: 0px;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */
        /* text-transform: uppercase; */
        /* Gray 1 */
        /* color: #333333;
        
    } */
    
    #actualLinks li:after {
        content: '';
        display: block;
        margin-top: 9px;
        height: 3px;
        width: 0px;
        background: #038AF6;
        transition: width .5s ease, background-color .5s ease;
    
    }
    
    #actualLinks li:hover:after {
        width: 100%;
        background: #038AF6;
    }
    
    #actualLinks li:hover{
        color: #038AF6;
    }
    
    
    .links{
        text-decoration: none;
        color: black;
    }
    
    
    .links-active{
        text-decoration: none;
        color:#038AF6;
       
    }
    
    .links-active:after {
        
        content: '';
        display: block;
        margin-top: 9px;
        height: 3px;
        width: 100%;
        background: #038AF6;
        
    
    }
    
    
    #cars{
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        border: none;
        margin-top: -2px;
    }
    
    #cars option{
        display:none;
        
    }
    
    #cars option:hover{
        display:contents;
    }
}