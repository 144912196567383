/* ------------------------------------------Section 1-------------------------------------------- */
#pssection1 img{
    position: absolute;
    height: 380px;
    left: 0px;
    top: 235px;
    width: 100%;
    overflow-x: hidden;
    
}

#pssec1 h1{
   
     /* width: 332px; */
    /*height: 89px; */
    /* left: 804px; */
    position: absolute;
    margin-left: 50px;
    top:330px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    line-height: 89px;
    /* identical to box height */
    
    /* background-color: aqua; */
    color: #FFFFFF;
   
    
}


#pshomeTocovid h6{
    position: absolute;
    /* width: 250px; */
    /*height: 22px; */
    /* left: 891.5px; */
    top: 434px;
    margin-left: -10px;
    font-family: Nunito;
    font-style: normal;
   
    font-size: 17px;
    line-height: 20px;
    /* identical to box height */

    
    color: #FFFFFF;
    overflow: hidden;
}

#pshomeTocovid h6{
    overflow: hidden;
}

#pshomeTocovid span{
    opacity: 0.6;
}

/* ------------------------------------------------------Privacy Shield Text------------------------------------------- */

#pscont{
   
   
  
    margin: 35% 14% 5% 14.5%;
    line-height: 30px;
    font-weight: 600;
    font-size: 16px;
   
}

#pscont h4{
    overflow: hidden;
    font-weight: 700;
}

#pscont p{
    font-weight: 600;
}

#pscont h1{
    overflow: hidden;
    font-weight: 700;
    font-size: 55px;
}

#pscont h2{
    overflow: hidden;
    font-weight: 700;
    font-size: 48px;
}

#pscont h5{
    overflow: hidden;
    font-weight: 600;
}

#pscont li{
    font-weight: 500;
}


.pslinks{
    text-decoration: none;
    color: black;
    background-color: #038AF6;
    height: 1000px;
    background-size: 600px;
    margin-top: 30px;
}


.pslinks-active{
    text-decoration: none;
    color:#038AF6;
   
}
